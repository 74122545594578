import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
// Images
import close_img from './../../../assets/img/onboarding/close.svg'
import check_img from './../../../assets/img/onboarding/check.svg'
// Requests
import { dependencies } from '../../../tools/Dependencies'
import { auth } from '../../../tools/Auth'
import { httpClient } from '../../../tools/HttpClient'
import Waiting from './../../../views/Waiting/waiting';
// Redux
import { useSelector, useDispatch } from "react-redux"
import { updateOnboardingState } from '../../../redux/Onboarding/onboarding.actions'
// Steps Components
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepFive from './Steps/StepFive'
// Cancel Onboarding
import CancelOnboarding from './CancelOnboarding/CancelOnboarding'
// Text
import TextFile from './text.json'
// Style
import './Onboarding.scss'

let Text = TextFile[dependencies.getFromLocalStorage("lang")];
const setIndexDir =()=> {
    var html = document.getElementsByTagName("html")[0];
    var att = document.createAttribute("dir");
    if (dependencies.getFromLocalStorage("lang") === "ar") {
      att.value = "rtl";
    } else {
      att.value = "ltr";
    }
    html.setAttributeNode(att);
}

function Onboarding() {
    setIndexDir();
    // Redux State
    let state = useSelector(state => state.onboarding);
    const dispatch = useDispatch();

    // Call All requests in order
    useEffect (() => {
        if(!state.industryDataLoaded && !state.paymentSolutionsDataLoaded && !state.banksDataLoaded && !state.merchantDataLoaded){
            getIndustryList()
        }else if(state.industryDataLoaded && !state.paymentSolutionsDataLoaded && !state.banksDataLoaded && !state.merchantDataLoaded){
            getPaymentSolutions()
        }else if(state.industryDataLoaded && state.paymentSolutionsDataLoaded && !state.banksDataLoaded && !state.merchantDataLoaded){
            getBanksList()
        }else if(state.industryDataLoaded && state.paymentSolutionsDataLoaded && state.banksDataLoaded && !state.merchantDataLoaded){
            loadMerchantData()
        }else if(state.industryDataLoaded && state.paymentSolutionsDataLoaded && state.banksDataLoaded && state.merchantDataLoaded){
            loadMerchantDocs()
        }
    }, [state.industryDataLoaded, state.paymentSolutionsDataLoaded, state.banksDataLoaded, state.merchantDataLoaded]);
    // Call Current Step after data loaded
    useEffect (() => {
        if(state.merchantDocsLoaded == true){
            // Handle Select Payment Solution if any child is selected
            if(state.servicesArr.length != 0 && state.servicesArr != null){
                state.paymentSolutionsArr.map((solution)=>{
                    solution.children.map(ele => {
                        if(state.servicesArr.includes(ele.id)){
                            if(!state.paymentSolutions.includes(solution.name)){
                                let newState = {...state}
                                newState.paymentSolutions.push(solution.name);
                                dispatch(updateOnboardingState(newState))
                            }
                        }
                    })
                })
            }
            handleCurrentStep();
        }
    }, [state.merchantDocsLoaded]);

    // Load Industry List
    const getIndustryList = () => {
        //send request
        let config ={
            headers: {
                "Authorization": "Base64 " + auth.getMerchantProfileToken(),
                "Accept-Language": dependencies.getFromLocalStorage("lang")
            }
        }
        httpClient.onboarding_get(
            '/onboarding/industry/list',
            config,
            (resp)=>{
                let data_arr = [];
                resp.data.results.map(ele => {
                    data_arr.push({value: ele.id, label: ele.title})
                    return data_arr
                })
                dispatch(updateOnboardingState({...state, businessIndustryList: data_arr, industryDataLoaded: true}))
            },
            (error)=>{
                if(error.response){
                    if(error.response.status === 403){
                        auth.logout()
                    }else if(error.response.data && error.response.data.message){
                        let new_state = {...state};
                        new_state.publicWaiting = false;
                        new_state.publicError = true;
                        new_state.publicErrorMessage = error.response.data.message;
                        dispatch(updateOnboardingState(new_state))
                    }
                }else{
                    let new_state = {...state};
                    new_state.publicWaiting = false;
                    new_state.publicError = true;
                    new_state.publicErrorMessage = Text.onboardingModal.faildMessage;
                    dispatch(updateOnboardingState(new_state))
                }
            }
        )
    }
    // Load Payment Solutions
    const getPaymentSolutions = () => {
        //send request
        let config ={
            headers: {
                "Accept-Language": "en"
            }
        }
        httpClient.onboarding_get(
            '/onboarding/services',
            config,
            (resp)=>{
                let new_state = {...state};
                new_state.paymentSolutionsArr = resp.data.results;
                new_state.paymentSolutionsDataLoaded = true;
                dispatch(updateOnboardingState(new_state))
            },
            (error)=>{
                if(error.response){
                    if(error.response.status === 403){
                        auth.logout()
                    }else if(error.response.data && error.response.data.message){
                        let new_state = {...state};
                        new_state.publicWaiting = false;
                        new_state.publicError = true;
                        new_state.publicErrorMessage = error.response.data.message;
                        dispatch(updateOnboardingState(new_state))
                    }
                }else{
                    let new_state = {...state};
                    new_state.publicWaiting = false;
                    new_state.publicError = true;
                    new_state.publicErrorMessage = Text.onboardingModal.faildMessage;
                    dispatch(updateOnboardingState(new_state))
                }
            }
        )
    }
    // Load Banks List
    const getBanksList = () => {
        let config ={
            headers: {
                "Accept-Language": dependencies.getFromLocalStorage("lang")
            }
        }
        httpClient.onboarding_get(
            '/onboarding/banks/list',
            config,
            (resp)=>{
                let data_arr = [];
                resp.data.result.map(ele => {
                    data_arr.push({value: ele.abbreviation, label: dependencies.getFromLocalStorage("lang") == "ar" ? ele.bank_name_ar : ele.bank_name})
                    return data_arr
                })
                dispatch(updateOnboardingState({...state, banksList: data_arr, banksDataLoaded: true}))
            },
            (error)=>{
                if(error.response){
                    if(error.response.status === 403){
                        auth.logout()
                    }else if(error.response.data && error.response.data.message){
                        dispatch(updateOnboardingState({...state, publicWaiting: false, publicError: error.paymob_error_details, publicErrorMessage: error.response.data.message}))
                    }
                }else{
                    dispatch(updateOnboardingState({...state, publicWaiting: false, publicError: error.paymob_error_details, publicErrorMessage: Text.onboardingModal.faildMessage}))
                }
            }
        )
    }
    // Load Merchant Data
    const loadMerchantData = () => {
        let config ={
            headers: {
                "Accept-Language": dependencies.getFromLocalStorage("lang"),
                "Authorization": "Base64 " + auth.getMerchantProfileToken()
            }
        }
        httpClient.onboarding_get(
            '/onboarding/merchants/me',
            config,
            (resp)=>{
                let new_state = {...state}
                new_state.publicWaiting = false;
                new_state.merchantDataLoaded = true;
                // Step (1) Business Info
                new_state.businessType = resp.data.type;
                if(resp.data.industry != 0){
                    new_state.businessIndustry = resp.data.industry;
                }
                if(resp.data.other_work_industry != "" && resp.data.other_work_industry != null){
                    new_state.enterOtherIndustry = true;
                    new_state.otherBusinessIndustry = resp.data.other_work_industry;
                }
                if(resp.data.company_url != null){
                    new_state.onlinePresence = resp.data.company_url;
                }
                // Step (2) Payment Solutions
                new_state.servicesArr = resp.data.services;
                // Step (4) Settlements
                if(resp.data.wallet_phone_number){
                    new_state.settlementMethod = "wallet";
                    new_state.phoneNumber = resp.data.wallet_phone_number;
                }else{
                    new_state.settlementMethod = "bankAccount";
                    if(resp.data.company_bank_name != null){
                        new_state.bankName = resp.data.company_bank_name;
                    }
                    if(resp.data.company_bank_account_name != null){
                        new_state.bankAccountName = resp.data.company_bank_account_name;
                    }
                    if(resp.data.company_bank_account_number != null){
                        new_state.bankAccountNumber = resp.data.company_bank_account_number;
                    }
                    if(resp.data.company_iban != null){
                        new_state.ibanNumber = resp.data.company_iban;
                    }
                }
                // Step (5) Terms and Conditions
                new_state.merchantType = resp.data.merchant_type;
                dispatch(updateOnboardingState(new_state))
            },
            (error)=>{
                if(error.response){
                    if(error.response.status === 403){
                        auth.logout()
                    }else if(error.response.data && error.response.data.message){
                        let new_state = {...state};
                        new_state.publicWaiting = false;
                        new_state.publicError = true;
                        new_state.publicErrorMessage = error.response.data.message;
                        dispatch(updateOnboardingState(new_state))
                    }
                }else{
                    let new_state = {...state};
                    new_state.publicWaiting = false;
                    new_state.publicError = true;
                    new_state.publicErrorMessage = Text.onboardingModal.faildMessage;
                    dispatch(updateOnboardingState(new_state))
                }
            }
        )
    }
    // Load Merchant Documents
    const loadMerchantDocs = () => {
        let config ={
            headers: {
                "Accept-Language": dependencies.getFromLocalStorage("lang"),
                "Authorization": "Base64 " + auth.getMerchantProfileToken()
            }
        }
        httpClient.onboarding_get(
            '/onboarding/merchants/docs',
            config,
            (resp)=>{
                let new_state = {...state}
                new_state.publicWaiting = false;
                new_state.merchantDocsLoaded = true;
                // Step (3) Documents
                new_state.idFile = resp.data.signatory_national_id;
                new_state.passportFile = resp.data.passport_id_file;
                new_state.commercialRegisterFile = resp.data.company_registration_document;
                new_state.taxCardFile = resp.data.company_tax_card;
                new_state.posContractFile = resp.data.rent_contract_or_utility_bill;
                // to know if the file is returned from backend or uploaded by user
                // Detect id type
                if(resp.data.signatory_national_id != null && resp.data.passport_id_file == null){
                    new_state.idType = "nationalID";
                    new_state.idFileDownloaded = true;
                    new_state.idAlreadySent = true;
                }else if(resp.data.signatory_national_id == null && resp.data.passport_id_file != null){
                    new_state.idType = "passport";
                    new_state.passportFileDownloaded = true;
                    new_state.passportAlreadySent = true;
                    new_state.residencyEvidenceFile = resp.data.residency_evidence;
                    if(resp.data.residency_evidence != null){
                        new_state.residencyEvidenceFileDownloaded = true;
                    }
                }else if(resp.data.signatory_national_id != null && resp.data.passport_id_file != null){
                    new_state.idType = "nationalID";
                    new_state.idFileDownloaded = true;
                    new_state.idAlreadySent = true;
                    new_state.passportFileDownloaded = true;
                    new_state.passportAlreadySent = true;
                    new_state.residencyEvidenceFile = resp.data.residency_evidence;
                    if(resp.data.residency_evidence != null){
                        new_state.residencyEvidenceFileDownloaded = true;
                    }
                }else{
                    new_state.idType = "nationalID";
                }
                if(resp.data.company_registration_document != null){
                    new_state.commercialRegisterFileDownloaded = true;
                }
                if(resp.data.company_tax_card != null){
                    new_state.taxCardFileDownloaded = true;
                }
                if(resp.data.rent_contract_or_utility_bill != null){
                    new_state.posContractFileDownloaded = true;
                }
                dispatch(updateOnboardingState(new_state))
            },
            (error)=>{
                if(error.response){
                    if(error.response.status === 403){
                        auth.logout()
                    }else if(error.response.data && error.response.data.message){
                        let new_state = {...state};
                        new_state.publicWaiting = false;
                        new_state.publicError = true;
                        new_state.publicErrorMessage = error.response.data.message;
                        dispatch(updateOnboardingState(new_state))
                    }
                }else{
                    let new_state = {...state};
                    new_state.publicWaiting = false;
                    new_state.publicError = true;
                    new_state.publicErrorMessage = Text.onboardingModal.faildMessage;
                    dispatch(updateOnboardingState(new_state))
                }
            }
        )
    }

    // Handle Current Step
    const handleCurrentStep = () => {
        let new_state = {...state}
        if(state.businessType && state.businessIndustry && state.onlinePresence){
            new_state.step = 2;
            if(state.servicesArr.length != 0){
                new_state.step = 3;
                if(state.idFile || state.passportFile ){
                    new_state.step = 4;
                    if((state.bankName && state.bankAccountName && state.bankAccountNumber && state.ibanNumber ) || state.phoneNumber ){
                        new_state.step = 5;
                    }
                }
            }
        }else{
            new_state.step = 1;
        }
        dispatch(updateOnboardingState(new_state))
    }
    // Handle Close Skip modal
    const close_model = (e) => {
        e.preventDefault();
        dispatch(updateOnboardingState({...state, cancelModalOpen: true}))
    }

    // Render Side Nav
    const renderSideNav = () => {
        let side_nav = [
            {num: 1, name: Text.onboardingModal.businessInfo.title},
            {num: 2, name: Text.onboardingModal.paymentSolutions.title},
            {num: 3, name: Text.onboardingModal.documents.title},
            {num: 4, name: Text.onboardingModal.settlements.title},
            {num: 5, name: Text.onboardingModal.termsAndConditions.title}
        ]
        return(
            side_nav.map((ele, key) => (
                <div className='steps_container' key={key}>
                    <p className={state.step >= ele.num ? "active" : ""}>
                        {state.step > ele.num ?
                            <span className="passed"><img src={check_img} alt="check" /></span>
                        :
                            <span>0{ele.num}</span>
                        }
                        {ele.name}
                    </p>
                    {ele.num != 5 && <p className={`dashed_line ${state.step > ele.num ? "active" : ""}`}></p>}
                </div>
            ))
        ) 
    }
    // Render Mob Nav
    const renderMobNav = () => {
        let side_nav = [
            {num: 1, name: Text.onboardingModal.businessInfo.title},
            {num: 2, name: Text.onboardingModal.paymentSolutions.title},
            {num: 3, name: Text.onboardingModal.documents.title},
            {num: 4, name: Text.onboardingModal.settlements.title},
            {num: 5, name: Text.onboardingModal.termsAndConditions.title}
        ]
        return(
            
            <div className="steps_mob_container onboarding_mob">
                {side_nav.map((ele, key) => (
                    state.step > ele.num ?
                        <span className="passed" key={key}></span>
                    : state.step == ele.num ?
                        <span className="active" key={key}></span>
                    :
                        <span key={key}></span>
                ))}
            </div>
        ) 
    }
    // Render Page Content
    const renderBody = () => (
        <div className='onboarding_container'>
            <Row>
                <Col lg="4" md="4" className="left_side onboarding_web">
                    {renderSideNav()}
                </Col>
                <Col lg="8" md="8" className="right_side">
                    <h3>
                        {Text.onboardingModal.header}            
                        <img name="cancel_onboarding" src={close_img} onClick={close_model}/>
                    </h3>
                    {state.publicWaiting ?
                        <Waiting height="0" />
                    :   
                        state.publicError ?
                            <div className="onboarding_error_page">
                                <div className="alert alert-danger">{state.publicErrorMessage + " " + state.publicError}</div>
                            </div>
                        :
                            <>
                                {renderMobNav()}
                                {state.step == 1 && <StepOne Text={Text} />}
                                {state.step == 2 && <StepTwo Text={Text} />}
                                {state.step == 3 && <StepThree Text={Text} />}
                                {state.step == 4 && <StepFour Text={Text} />}
                                {state.step == 5 && <StepFive Text={Text} />}
                            </>
                    }
                </Col>
            </Row>
        </div>
    )
    
    return (
        <div>
            <CancelOnboarding Text={Text} />
            {renderBody()}
        </div>
    )
}

export default Onboarding