const STATEMENTS_SERVICE_NAME_PROD = "MONTHLY STATEMENT";
const STATEMENTS_SERVICE_NAME_ALPHA = "MERCHANT STATEMENT";
const DIGEST_SERVICE_NAME = "DIGEST";
const TOAST_CONFIG = {
  theme: "colored",
  position: "top-center",
  closeOnClick: true,
  pauseOnHover: true,
  autoClose: 4000
};
const INT_PHONE_NUM_REGEX = /^(?:\+\d{1,3}\s?)?\d{8,15}$/;
const PHONE_NUM_REGEX = /^\+?\d+$/;
const CONSEQUTIVE_DOTS_REGEX = /(\.\.)/;
const DISALLOWED_CHARACTERS_REGEX = '/^,+/g';
const FULL_NAME_REGEX = /^[\u0600-\u06FF\u0750-\u077F\uFB50-\uFBC1\uFBD3-\uFDFF\uFE70-\uFEFCa-zA-Z\s']+$/;
const CONFIRM_PASSWORD_REGEX = {
  UPPER_CASE: /(?=.*?[A-Z])/,
  LOWER_CASE: /(?=.*?[a-z])/,
  DIGITS_SPECIAL_CHAR: /(.*[0-9].*[@#$&])|(.*[@#$&].*[0-9])/,
};
const DISALLOWED_HTML_REGEX = /<[^>]*>?/;
const OTHER_COUNTRY_NUM_REF = 'otherCountryNum'; 
const MAX_MOBILE_SIZE = 480;
const EGY_PHONE_START = '201';
const EGY_COUNTRY_CODE = '20';

const ENTER_KEYS = ["Enter", ",", " "];
const BACKSPACE_KEY = "Backspace";

const LANGUAGES = {
  EN: "en",
  AR: "ar"
};

const extractFileName = (contentDisposition) => {
  if (contentDisposition) {
    const [, filename] = contentDisposition.split('filename=');
    if (filename) {
      return filename.replace(/["']/g, '');
    };
  }
  return null;
}

export {
  STATEMENTS_SERVICE_NAME_PROD,
  STATEMENTS_SERVICE_NAME_ALPHA,
  DIGEST_SERVICE_NAME,
  TOAST_CONFIG,
  INT_PHONE_NUM_REGEX,
  PHONE_NUM_REGEX,
  CONSEQUTIVE_DOTS_REGEX, 
  DISALLOWED_CHARACTERS_REGEX,
  FULL_NAME_REGEX,
  CONFIRM_PASSWORD_REGEX,
  DISALLOWED_HTML_REGEX,
  OTHER_COUNTRY_NUM_REF,
  MAX_MOBILE_SIZE,
  EGY_PHONE_START,
  EGY_COUNTRY_CODE,
  ENTER_KEYS,
  BACKSPACE_KEY,
  LANGUAGES,
  extractFileName
};