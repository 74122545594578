import axios from "axios";
import { appConfig } from "./AppConfig";
import { dependencies } from "./Dependencies";

class HttpClient {
  handleErrorDetails = (method, url, path, error) => {
    let x_paymob_id;
    let status;

    if (error && error.response) {
      x_paymob_id = error.response.headers["x-paymob-id"];
      status = error.response.status;
    } else {
      if (error.code === "ECONNABORTED") {
        status = "Request timed out";
      } else {
        status = error.message;
      }
    }

    const errorDetails = dependencies.errorDetails(method, url, path, status, x_paymob_id);

    error.paymob_error_details = errorDetails;
  };

  get(path, config, successCb, errorCb) {
    let url = appConfig.getServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .get(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("GET", url, path, error);
        return errorCb(error);
      });
  }
  get_bills(path, config, successCb, errorCb) {
    let url = appConfig.getBillsServerURL() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .get(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("GET", url, path, error);
        return errorCb(error);
      });
  }
  get_full_url(domain, path, config, successCb, errorCb) {
    let url = domain + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .get(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("GET", url, path, error);
        return errorCb(error);
      });
  }
  get_growth(path, config, successCb, errorCb) {
    let url = appConfig.getGrowthServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .get(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("GET", url, path, error);
        return errorCb(error);
      });
  }
  post(path, config, data, successCb, errorCb) {
    let url = appConfig.getServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .post(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("POST", url, path, error);
        return errorCb(error);
      });
  }
  post_full_url(domain, path, config, data, successCb, errorCb) {
    let url = domain + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .post(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("POST", url, path, error);
        return errorCb(error);
      });
  }
  post_growth(path, config, data, successCb, errorCb) {
    let url = appConfig.getGrowthServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .post(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("POST", url, path, error);
        return errorCb(error);
      });
  }
  delete(path, config, successCb, errorCb) {
    let url = appConfig.getServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .delete(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("DELETE", url, path, error);
        return errorCb(error);
      });
  }
  delete_full_url(domain, path, config, successCb, errorCb) {
    let url = domain + path;
    config.timeout = 100 * 1000;
    axios
      .delete(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("DELETE", url, path, error);
        return errorCb(error);
      });
  }
  put(path, config, data, successCb, errorCb) {
    let url = appConfig.getServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .put(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("PUT", url, path, error);
        return errorCb(error);
      });
  }
  put_full_url(domain, path, config, data, successCb, errorCb) {
    let url = domain + path;
    config.timeout = 100 * 1000;
    axios
      .put(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("PUT", url, path, error);
        return errorCb(error);
      });
  }
  // Onboarding
  onboarding_get(path, config, successCb, errorCb) {
    let url = appConfig.getOnboardingServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .get(url, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("GET", url, path, error);
        return errorCb(error);
      });
  }
  onboarding_post(path, config, data, successCb, errorCb) {
    let url = appConfig.getOnboardingServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .post(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("POST", url, path, error);
        return errorCb(error);
      });
  }
  onboarding_patch(path, config, data, successCb, errorCb) {
    let url = appConfig.getOnboardingServerUrl() + path;
    //config.headers["Cache-Control"]= "no-cache";
    config.timeout = 100 * 1000;
    axios
      .patch(url, data, config)
      .then(successCb)
      .catch(error => {
        this.handleErrorDetails("PATCH", url, path, error);
        return errorCb(error);
      });
  }
}

export let httpClient = new HttpClient();
